import { FC, ReactNode, useEffect, useRef, useState } from "react";
import {
  Controller,
  FieldErrors,
  Path,
  UseFormRegisterReturn,
} from "react-hook-form";
import { AiOutlineCamera } from "react-icons/ai";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { FiFile } from "react-icons/fi";
import { MdOutlinePhotoCamera } from "react-icons/md";

import { CloseIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  ListItem,
  Select,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";

import { useFetcher, useParams } from "@remix-run/react";

import CustomImage from "~/components/CustomImage";
import { RegisterForm, UpdateForm } from "~/components/form/types";
import { useMemberContext } from "~/context/member";
import { useTranslation } from "~/libs/i18n";
import { type action as MediaAction } from "~/routes/$region.$locale.api.media._index";
import { ModelTypes } from "~/zeus/index";

const Days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

type FileUploadProps = {
  register?: UseFormRegisterReturn;
  setValue?: any;
  name: Path<RegisterForm>;
  accept?: string;
  multiple?: boolean;
  children?: ReactNode;
  defaultFile?: ModelTypes["Media"];
  role?: string;
};

export const CommentFileUpload = (props: any) => {
  const { register, accept, multiple, children, setValue, name, defaultFile } =
    props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register as {
    ref: (instance: HTMLInputElement | null) => void;
  };
  const handleClick = () => inputRef.current?.click();
  const fetcher = useFetcher<typeof MediaAction>();

  const [files, setFiles] = useState<FileList>();

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFiles(e.target.files);
  };
  useEffect(() => {
    if (files) {
      const formData = new FormData();
      formData.append("file", files?.[0]);
      fetcher.submit(formData, {
        method: "post",
        action: "/hk/zh/api/media?index",
        encType: "multipart/form-data",
      });
    }
  }, [files]);

  useEffect(() => {
    if (fetcher?.data) {
      setValue(name, fetcher.data?.doc);
    }
  }, [fetcher?.data]);

  return (
    <>
      <InputGroup onClick={handleClick}>
        <input
          type={"file"}
          multiple={multiple || false}
          hidden
          accept={accept}
          {...rest}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
          onChange={handleChange}
        />
        <Button
          alignSelf={"flex-end"}
          variant="link"
          isLoading={
            fetcher.state === "loading" || fetcher.state === "submitting"
          }
          leftIcon={<AiOutlineCamera style={{ fontSize: 20 }} />}
          justifyContent="flex-end"
          className="commentUploadImage"
        />
      </InputGroup>
    </>
  );
};

export const FileUpload = (props: FileUploadProps) => {
  const { register, accept, multiple, children, setValue, name, defaultFile } =
    props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register as {
    ref: (instance: HTMLInputElement | null) => void;
  };
  const handleClick = () => inputRef.current?.click();
  const fetcher = useFetcher<typeof MediaAction>();
  const t = useTranslation();

  const [files, setFiles] = useState<FileList>();
  const [uploadedDoc, setUploadedDoc] = useState<ModelTypes["Media"] | null>();
  const { locale, region } = useParams();
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFiles(e.target.files);
  };
  useEffect(() => {
    if (files) {
      const formData = new FormData();
      formData.append("file", files?.[0]);
      fetcher.submit(formData, {
        method: "post",
        action: `/${region}/${locale}/api/media?index`,
        encType: "multipart/form-data",
      });
    }
  }, [files]);

  useEffect(() => {
    if (fetcher?.data) {
      setUploadedDoc(fetcher.data?.doc);
      setValue(name, fetcher.data?.doc);
    }
  }, [fetcher?.data]);

  useEffect(() => {
    if (defaultFile) {
      setUploadedDoc(defaultFile);
    }
  }, []);

  const handleDelete = () => {
    setUploadedDoc(null);
    setValue(name, undefined);
  };

  return (
    <>
      {uploadedDoc?.url && (
        <Box mb={4} position={"relative"}>
          <Box
            pos={"absolute"}
            top={2}
            right={2}
            rounded={99}
            bgColor="white"
            boxSize={8}
            cursor="pointer"
            _hover={{ opacity: 0.8 }}
            onClick={() => handleDelete()}
          >
            <Center h="full">
              <CloseIcon color="brand.timable-yellow" />
            </Center>
          </Box>
          <CustomImage media={uploadedDoc} src={uploadedDoc?.url} w="auto" />
        </Box>
      )}

      <InputGroup onClick={handleClick}>
        <input
          type={"file"}
          multiple={multiple || false}
          hidden
          accept={accept}
          {...rest}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
          onChange={handleChange}
        />
        <Button
          isLoading={
            fetcher.state === "loading" || fetcher.state === "submitting"
          }
          leftIcon={<Icon as={FiFile} />}
        >
          {t("form.upload")}
        </Button>
      </InputGroup>
    </>
  );
};

export const AvatarUpload = (props: FileUploadProps) => {
  const { register, accept, multiple, setValue, name, defaultFile, role } =
    props;
  const { user, update } = useMemberContext();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register as {
    ref: (instance: HTMLInputElement | null) => void;
  };
  const handleClick = () => inputRef.current?.click();
  const fetcher = useFetcher<typeof MediaAction>();

  const [files, setFiles] = useState<FileList>();
  const [uploadedDoc, setUploadedDoc] = useState<ModelTypes["Media"] | null>();
  const { locale, region } = useParams();
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFiles(e.target.files);
  };
  useEffect(() => {
    if (files) {
      const formData = new FormData();
      formData.append("file", files?.[0]);
      fetcher.submit(formData, {
        method: "post",
        action: `/${region}/${locale}/api/media?index`,
        encType: "multipart/form-data",
      });
    }
  }, [files]);

  useEffect(() => {
    if (fetcher?.data) {
      setUploadedDoc(fetcher.data?.doc);

      let input: any; // Partial<Member>

      if (role === "member") {
        input = {
          member: {
            avatar: fetcher.data?.doc?.id,
          },
        };
      } else {
        input = {
          organiser: {
            logo: fetcher.data?.doc?.id,
          },
        };
      }

      update({ id: user?.id as string, input });
    }
  }, [fetcher?.data]);

  useEffect(() => {
    if (defaultFile) {
      setUploadedDoc(defaultFile);
    }
  }, [defaultFile]);

  const handleDelete = () => {
    setUploadedDoc(null);
    setValue(name, undefined);
  };

  return (
    <Box pos={"relative"}>
      <Avatar
        pb={2}
        size="xl"
        rounded="full"
        src={uploadedDoc?.url}
        onClick={handleClick}
        w={40}
        h={40}
      />
      <Tooltip label="建議尺寸為200px x 200px">
        <Button
          bgColor={"brand.light-grey"}
          rounded={99}
          pos={"absolute"}
          bottom={1}
          right={1}
          w={8}
          h={8}
          cursor="pointer"
          _hover={{ opacity: 0.8 }}
          variant="link"
          isLoading={
            fetcher.state === "loading" || fetcher.state === "submitting"
          }
        >
          <InputGroup onClick={handleClick} h={10} w={"full"}>
            <input
              type={"file"}
              multiple={multiple || false}
              hidden
              accept={accept}
              {...rest}
              ref={(e) => {
                ref(e);
                inputRef.current = e;
              }}
              onChange={handleChange}
            />
            <Center h="full" w="full">
              <Icon as={MdOutlinePhotoCamera} w={4} h={4} />
            </Center>
          </InputGroup>
        </Button>
      </Tooltip>
    </Box>
  );
};

export const TextField: FC<{
  register: any;
  errors: FieldErrors;
  name: Path<RegisterForm>;
  option?: object;
  type: string;
  helperText?: string;
  labelText?: string;
  placeholderText?: string;
  errorText?: string;
  disabled?: boolean;
}> = ({
  errors,
  name,
  helperText,
  option,
  type,
  labelText,
  register,
  placeholderText,
  errorText,
  disabled = false,
}) => {
  return (
    <FormControl isInvalid={!!errors[name]}>
      {labelText && <FormLabel>{labelText}</FormLabel>}
      <Input
        {...register(name, option ?? {})}
        type={type}
        placeholder={placeholderText ?? ""}
        disabled={disabled}
        _autofill={false}
      />
      {helperText && (
        <FormHelperText fontSize={["xs"]}>{helperText}</FormHelperText>
      )}
      {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
    </FormControl>
  );
};

export const SelectField: FC<{
  register: any;
  errors: FieldErrors;
  name: Path<RegisterForm>;
  option?: object;
  options: Array<any>;
  helperText?: string;
  labelText?: string;
  placeholderText?: string;
  errorText?: string;
  defaultValue?: string;
  disabled?: boolean;
}> = ({
  errors,
  name,
  helperText,
  option,
  options,
  labelText,
  register,
  errorText,
  defaultValue,
  disabled = false,
}) => {
  return (
    <FormControl isInvalid={!!errors[name]}>
      {labelText && <FormLabel>{labelText}</FormLabel>}
      <Select
        {...register(name, option ?? {})}
        defaultValue={defaultValue}
        isDisabled={disabled}
      >
        {options?.map((option) => (
          <option
            key={`${option?.label}-${option?.value}`}
            value={option?.value}
          >
            {option?.label}
          </option>
        ))}
      </Select>
      {helperText && (
        <FormHelperText fontSize={["xs"]}>{helperText}</FormHelperText>
      )}
      {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
    </FormControl>
  );
};

export const PasswordField: FC<{
  register: any;
  errors: FieldErrors;
  name: Path<RegisterForm>;
  option?: object;
  helperText?: string;
  labelText?: string;
  placeholderText?: string;
  errorText?: any;
  disableReminder?: boolean;
  value?: any;
}> = ({
  errors,
  name,
  helperText,
  option,
  labelText,
  register,
  placeholderText,
  errorText,
  disableReminder = true,
  value,
}) => {
  const t = useTranslation();

  const [showPassword, setShowPassword] = useState<Boolean>();

  const parseErrorText = errorText ? JSON.parse(errorText) : "";

  return (
    <FormControl isInvalid={!!errors[name]}>
      {labelText && <FormLabel>{labelText}</FormLabel>}
      <InputGroup>
        <Input
          type={showPassword ? "text" : "password"}
          {...register(name, option ?? {})}
          placeholder={placeholderText ?? ""}
          // onFocus={() => setShowPasswordReminder(true)}
        />
        <InputRightElement>
          <Box cursor="pointer" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <BsEye style={{ color: "#F5A423" }} />
            ) : (
              <BsEyeSlash style={{ color: "#F5A423" }} />
            )}
          </Box>
        </InputRightElement>
      </InputGroup>

      {!disableReminder && (
        <FormHelperText pl={2}>
          <UnorderedList fontSize={["sm"]}>
            <ListItem
              color={
                parseErrorText?.atLeast8Characters ===
                t("form.reminder.atLeast8characters")
                  ? "red"
                  : !value
                    ? "inherit"
                    : "green"
              }
            >
              {t("form.reminder.atLeast8characters")}
            </ListItem>
            <ListItem
              color={
                parseErrorText?.hasUpperAndLowerCase ===
                t("form.reminder.atLeast1UppercaseAnd1LowercaseLetter")
                  ? "red"
                  : !value
                    ? "inherit"
                    : "green"
              }
            >
              {t("form.reminder.atLeast1UppercaseAnd1LowercaseLetter")}
            </ListItem>
            <ListItem
              color={
                parseErrorText?.hasNumber === t("form.reminder.atLeast1Number")
                  ? "red"
                  : !value
                    ? "inherit"
                    : "green"
              }
            >
              {t("form.reminder.atLeast1Number")}
            </ListItem>
          </UnorderedList>
        </FormHelperText>
      )}
      {helperText && (
        <FormHelperText fontSize={["xs"]}>{helperText}</FormHelperText>
      )}
      {/* {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>} */}
    </FormControl>
  );
};

export const ConfirmPasswordField: FC<{
  register: any;
  errors: FieldErrors;
  name: Path<RegisterForm>;
  option?: object;
  helperText?: string;
  labelText?: string;
  placeholderText?: string;
  errorText?: string;
}> = ({
  errors,
  name,
  helperText,
  option,
  labelText,
  register,
  placeholderText,
  errorText,
}) => {
  const t = useTranslation();
  const [showCFPassword, setShowCFPassword] = useState<Boolean>();
  return (
    <FormControl isInvalid={!!errors[name]}>
      {labelText && <FormLabel>{labelText}</FormLabel>}
      <InputGroup>
        <Input
          type={showCFPassword ? "text" : "password"}
          {...register(name, option ?? {})}
          placeholder={placeholderText ?? ""}
        />
        <InputRightElement>
          <Box
            cursor="pointer"
            onClick={() => setShowCFPassword(!showCFPassword)}
          >
            {showCFPassword ? (
              <BsEye style={{ color: "#F5A423" }} />
            ) : (
              <BsEyeSlash style={{ color: "#F5A423" }} />
            )}
          </Box>
        </InputRightElement>
      </InputGroup>

      {helperText && (
        <FormHelperText fontSize={["xs"]}>{helperText}</FormHelperText>
      )}
      {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
    </FormControl>
  );
};

export const PhoneField: FC<{
  register: any;
  errors: FieldErrors;
  name: Path<RegisterForm>;
  option?: object;
  helperText?: string;
  labelText?: string;
  placeholderText?: string;
  errorText?: string;
}> = ({
  errors,
  name,
  helperText,
  option,
  labelText,
  register,
  placeholderText,
  errorText,
}) => {
  return (
    <FormControl isInvalid={!!errors[name]}>
      {labelText && <FormLabel>{labelText}</FormLabel>}
      <InputGroup gap={2}>
        <Select w="auto">
          <option value="">+852</option>
        </Select>
        <Input
          flex={1}
          type="text"
          {...register(name, option ?? {})}
          placeholder={placeholderText ?? ""}
        />
      </InputGroup>
      {helperText && (
        <FormHelperText fontSize={["xs"]}>{helperText}</FormHelperText>
      )}
      {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
    </FormControl>
  );
};

export const DatePickerField: FC<{
  register: any;
  control: any;
  errors: FieldErrors;
  name: Path<UpdateForm>;
  helperText?: string;
  labelText?: string;
  // placeholderText?: string;
  errorText?: string;
  yy?: Path<UpdateForm> | null;
  mm?: Path<UpdateForm> | null;
  dd?: Path<UpdateForm> | null;
  options?: any;
}> = ({
  register,
  control,
  errors,
  name,
  helperText,
  labelText,
  // placeholderText,
  errorText,
  yy,
  mm,
  dd,
  options,
}) => {
  const year = new Date().getFullYear();
  const years = Array.from(new Array(100), (_, index) => year - index);

  const errorsYY = yy != undefined ? errors[yy as keyof typeof errors] : "";
  const errorsMM = mm != undefined ? errors[mm as keyof typeof errors] : "";
  const errorsDD = dd != undefined ? errors[dd as keyof typeof errors] : "";

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControl isInvalid={!!errorsYY || !!errorsMM}>
            {labelText && <FormLabel>{labelText}</FormLabel>}
            <InputGroup gap={2}>
              {yy && (
                <Select {...register(yy, options ? options[0] : {})}>
                  <option value={""}>-</option>
                  {years?.map((year, index) => {
                    return (
                      <option key={`${year}-${index}`} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </Select>
              )}

              <Select {...register(mm, options ? options[1] : {})}>
                <option value={""}>-</option>
                {[...Array(12)].map((_, index) => {
                  let _index = index + 1;
                  let value = (_index >= 10 ? _index : `0${_index}`).toString();
                  return (
                    <option key={_index} value={value}>
                      {value}
                    </option>
                  );
                })}
              </Select>
              {dd && (
                <Select
                  {...register(dd, options ? options[2] : {})}
                  disabled={!field.value}
                >
                  <option value={""}>-</option>
                  {[...Array(Days[parseInt(field.value as string) - 1])].map(
                    (_, index) => {
                      let _index = index + 1;
                      let value = _index >= 10 ? _index : `0${_index}`;
                      return (
                        <option key={_index} value={value}>
                          {value}
                        </option>
                      );
                    }
                  )}
                </Select>
              )}
            </InputGroup>
          </FormControl>
        );
      }}
    />
  );
};

export const NewsletterTextField: FC<{
  register: any;
  errors: FieldErrors;
  name: Path<RegisterForm>;
  option?: object;
  type: string;
  labelText?: string;
  placeholderText?: string;
  errorText?: string;
  disabled?: boolean;
  value?: string;
}> = ({
  errors,
  name,
  option,
  type,
  labelText,
  register,
  placeholderText,
  errorText,
  disabled = false,
  value,
}) => {
  return (
    <FormControl isInvalid={!!errors[name]}>
      {labelText && <FormLabel>{labelText}</FormLabel>}
      <Input
        {...register(name, option ?? {})}
        type={type}
        placeholder={errorText ?? placeholderText}
        disabled={disabled}
        _autofill={false}
        border={"none"}
        value={value}
      />
    </FormControl>
  );
};

// export const GenderField: FC<{
//   register: any;
//   control: any;
//   setValue: any;
//   errors: FieldErrors;
//   name: Path<UpdateForm>;
//   helperText?: string;
//   labelText?: string;
//   // placeholderText?: string;
//   errorText?: string;
//   yy?: Path<UpdateForm> | null;
//   mm?: Path<UpdateForm> | null;
//   dd?: Path<UpdateForm> | null;
// }> = ({
//   register,
//   control,
//   errors,
//   name,
//   helperText,
//   labelText,
//   // placeholderText,
//   errorText,
//   setValue,
//   yy,
//   mm,
//   dd,
// }) => {
//   const t = useTranslation();
//   return (
//     <Controller
//       name="gender"
//       control={control}
//       render={({ field }) => {
//         const selectedMale =
//           field.value === MemberUpdate_Member_gender_MutationInput.male;
//         return (
//           <Flex justifyContent={"space-between"} alignItems="center" pt={5}>
//             {labelText && <FormLabel>{labelText}</FormLabel>}

//             {field?.value && (
//               <Button
//                 variant="link"
//                 rounded={99}
//                 bgColor={selectedMale ? "#3A86FF" : "brand.timable-yellow"}
//                 onClick={() => {
//                   setValue(
//                     "gender",
//                     selectedMale
//                       ? MemberUpdate_Member_gender_MutationInput.female
//                       : MemberUpdate_Member_gender_MutationInput.male
//                   );
//                 }}
//               >
//                 <Flex
//                   p={1}
//                   alignItems="center"
//                   gap={2}
//                   direction={selectedMale ? "row" : "row-reverse"}
//                 >
//                   <Box rounded={99} bgColor="white" flex={1} p={1}>
//                     <BsGenderMale
//                       style={{
//                         color: selectedMale ? "#3A86FF" : "#F5A423",
//                       }}
//                     />
//                   </Box>
//                   <Box
//                     flex={1}
//                     pr={selectedMale ? 1 : 0}
//                     pl={selectedMale ? 0 : 1}
//                   >
//                     <Text color={"#FFF"} textAlign="center">
//                       {t(`form.${field?.value}`)}
//                     </Text>
//                   </Box>
//                 </Flex>
//               </Button>
//             )}
//           </Flex>
//         );
//       }}
//     />
//   );
// };
